.home {
  background-color: var(--dark);

  width: 100%;
  min-height: 100vh;

  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  position: relative;
}

.home .logo {
  margin-bottom: 6rem;
}

span {
  font-family: var(--font-mono);

  font-size: 2.2rem;
}

.header {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 4rem;
}

.header .name {
  font-family: var(--font-h1);

  color: white;

  font-size: 3.5rem;

  margin-bottom: 1rem;
}

.header .pro {
  font-family: var(--font-mono);

  letter-spacing: -5%;

  color: white;

  font-size: 1.6rem;
}

.pressing {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: var(--font-h1);

  color: white;

  padding: 1rem;

  border-radius: 8px;

  transition: 0.5s all ease;

  cursor: pointer;
}

.pressing:hover {
  background-color: #262231;
}

.pressing span {
  font-size: 1.3rem;
}

.pressing button {
  border-radius: 8px;

  background-color: var(--gray);

  padding: 0.5rem 1rem;

  border: none;

  font-family: var(--font-h1);
  font-weight: bold;

  margin: 0 0.3rem;

  cursor: pointer;
}

.pressing .buttons {
  margin: 0 0.5rem;

  display: flex;
}

.pressing img {
  width: 2rem;

  margin: 0 0 0 1rem;
}
