.hoverBlock {
  height: 100vh;
  width: 100%;

  position: fixed;

  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundFocus {
  height: 100vh;
  width: 100%;

  background-color: black;

  position: fixed;

  opacity: 0.8;

  z-index: 1000;
}

.container {
  width: 100%;

  max-width: 700px;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1300;
}

.modal {
  width: 500px;
  height: 100%;

  min-height: 400px;

  max-height: 520px;

  background: rgba(40, 42, 44, 0.6);
  backdrop-filter: blur(16px);

  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal span {
  font-family: 'biotif-regular';
  font-size: 1rem;
}

.modal h4 {
  font-family: 'biotif-bold';

  font-size: 0.8rem;

  color: white;

  padding: 1rem 0 0.3rem 1rem;

  display: block;
}

.modal h4:nth-child(3) {
  font-family: 'biotif-bold';

  color: white;

  padding: 1rem 0 1rem 1rem;

  display: block;
}

.modal ul {
  font-family: 'biotif-regular';

  color: var(--gray);

  list-style: none;

  display: block;

  width: 100%;
}

.modal ul a {
  padding: 1rem 1.5rem;

  display: flex;

  align-items: center;

  width: 100%;

  cursor: pointer;
}

.modal ul a:hover {
  border-left: 2px solid white;
  background-color: #383a3d;
}

.modal ul a img {
  width: 1.2rem;
  margin: 0 1rem 0 0;
  position: relative;
  top: -2px;
}

a {
  text-decoration: none;
  color: var(--gray);
}