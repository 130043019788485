@font-face {
  font-family: 'biotif-regular';
  src: url('./assets/fonts/biotif-regular.ttf');
}

@font-face {
  font-family: 'biotif-bold';
  src: url('./assets/fonts/biotif-bold.ttf');
}

@font-face {
  font-family: 'metropolis-regular';
  src: url('./assets/fonts/metropolis-regular.otf');
}

@font-face {
  font-family: 'metropolis-bold';
  src: url('./assets/fonts/metropolis-bold.otf');
}

@font-face {
  font-family: 'metropolis-light-italic';
  src: url('./assets/fonts/metropolis-light-italic.otf');
}

:root {
  --font-h1: 'Fira Code';
  --font-mono: 'Share Tech Mono';
  --font-text: 'biotif-regular';
  --blue: #8de0fb;
  --gray: #8f9ba8;
  --text-gray: #adadad;
  --dark: #191621;
  --very-dark: #050505;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 16px;
}

@media (max-width: 768px) {
  * {
    font-size: 15px;
  }
}

@media (max-width: 425px) {
  * {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  * {
    font-size: 12px;
  }
}
