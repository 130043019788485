.projects {
  background-color: var(--dark);
}

.container {
  max-width: 940px !important;

  margin: auto;

  display: flex;
  flex-direction: column;

  align-items: center;

  margin-bottom: 8rem;
  padding-top: 7rem;
}

.container .projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.container .title {
  display: flex;
  justify-content: left !important;
  align-items: flex-start;
  flex-wrap: wrap;

  margin: 4rem 6rem 6rem 6rem;
}

.container .title span {
  font-family: 'metropolis-bold';
  font-size: 4rem;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 2.5rem;
}

.container .title h4 {
  font-family: 'metropolis-regular';
  font-size: 1rem;
  font-weight: 400;
  color: #8DE0FB;
  letter-spacing: 0em;
  line-height: 1.5rem;
}

h1 {
  font-family: 'metropolis-bold';
  font-size: 4rem;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
}

@media (max-width: 768px) {
  .container .title span {
    font-size: 3rem;
  }
}