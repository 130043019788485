.mb1r {
  margin-bottom: 1rem;
}

.about {
  background-color: var(--dark);
  
  min-height: 100vh;

  padding-top: 7rem;
}

.container {
  max-width: 1080px !important;

  margin: auto;

  display: flex;
  flex-direction: column;

  align-items: center;

  margin-bottom: 8rem;
}

.title {
  margin: 4rem auto;
}

h1 {
  font-family: 'metropolis-bold';
  font-size: 4rem;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
}

.informations {
  display: flex;
  flex-direction: column;

  padding: 6rem 0 2rem 0;
}

.informations .row {
  display: flex;
  flex-wrap: wrap;

  width: 100%;

  margin-bottom: 8rem;
}

.informations .row .what {
  flex: 1;
}

.informations .row .description {
  flex: 2;
}

.informations .row .what h3 {
  font-family: 'metropolis-regular';
  font-size: 2rem;
  font-style: italic;
  color: #FFFFFF;
}

.informations .row .description {
  display: flex;
  flex-direction: column;

  row-gap: 1rem;
}

.informations .row .description p {
  font-family: 'metropolis-regular';
  font-size: 1.2rem;
  line-height: 1.7rem;

  color: var(--gray);
}

.informations .row .description p .whited {
  color: #FFFFFF;
  
  font-family: 'metropolis-regular';
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.informations .row .description .whti {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  row-gap: 0.5rem;

  color: var(--blue);
}

.informations .row .description .whti .where {
  font-family: 'biotif-bold';
  font-size: 1.5rem;
  letter-spacing: 0.3em;
}

.informations .row .description .whti .time {
  font-family: 'biotif-bold';
  font-size: 1.1rem;
  letter-spacing: 0.3em;
}

.informations .row .description .techs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  row-gap: 0.5rem;

  color: var(--blue);

  margin-bottom: 2rem;
}

.informations .row .description .techs .type {
  font-family: 'biotif-bold';
  font-size: 1.5rem;
  letter-spacing: 0.3em;
}

.informations .row .description .techs .name {
  font-family: 'biotif-regular';
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--text-gray);
}

.btnPdf {
  height: 5rem;
  width: 20rem;
  background: var(--blue);
  border: none;
  cursor: pointer;
}

.btnPdf span {
  font-family: 'biotif-bold';
  font-size: 1.4rem;
  text-align: center;
}

@media (max-width: 1024px) {
  .informations .row .what {
    padding-left: 3rem;
  }
}

@media (max-width: 768px) {
  .informations .row {
    padding: 0 3rem;
  }

  .informations .row .what {
    width: 100%;

    flex: unset;

    padding-left: 0;
    padding-bottom: 1rem;

    margin-bottom: 3rem;

    border-bottom: 1px solid var(--gray);
  }
}