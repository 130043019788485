.mb1r {
  margin-bottom: 1rem;
}

.articles {
  background-color: var(--dark);

  padding-top: 7rem;
}


.container {
  max-width: 940px;

  margin: auto;

  display: flex;
  flex-direction: column;

  align-items: center;

  margin-bottom: 8rem;
}

.container .title {
  display: flex;
  justify-content: left !important;
  align-items: flex-start;
  flex-wrap: wrap;

  margin: 4rem 6rem 6rem 6rem;
}

.container .title span {
  font-family: 'metropolis-bold';
  font-size: 4rem;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 2.5rem;
}

.container .title h4 {
  font-family: 'metropolis-regular';
  font-size: 1rem;
  font-weight: 400;
  color: #8DE0FB;
  letter-spacing: 0em;
  line-height: 1.5rem;
}

.container .articles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}

.container .articles__list .mini {
  font-family: 'metropolis-bold';
  font-size: 1rem;
  color: #FFFFFF;

  padding: 0 1rem;
  
  margin: 0 0 1rem 0;
}

.container .articles__list .no__data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
  column-gap: 5rem;
}

.container .articles__list .no__data img {
  width: 20rem;
}

.container .articles__list .no__data h3 {
  font-family: var(--font-text);
  color: #FFFFFF;

  max-width: 300px;

  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .container .title span {
    font-size: 3rem;
  }

  .container .articles__list .no__data img {
    margin-bottom: 3rem;
  }
}

@media (max-width: 425px) {
  .container .articles__list .no__data h3 {
    text-align: center;
  }
}