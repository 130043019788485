footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #27233A;

  height: 50rem;
  width: 100%;
}

footer .me {
  margin-bottom: 2rem;
}

footer .miniTitle {
  font-family: 'metropolis-regular';
  font-size: 1.5rem;
  color: #FFFFFF;
  letter-spacing: -0.05em;
  margin-bottom: 2rem;
}

footer .email {
  font-family: 'metropolis-bold';
  font-size: 2rem;
  font-style: normal;
  letter-spacing: -0.05em;
  color: #FFFFFF;
  margin-bottom: 6rem;
}

footer .socials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer .socials .icons {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
  color: #FFFFFF;
}

.bottom {
  width: 100%;
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #FFFFFF;
}

.bottom span {
  font-family: 'biotif-regular';
  font-size: 1.5rem;
  letter-spacing: -0.05em;
  text-align: center;
}