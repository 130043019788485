.article {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;

  padding: 1rem;

  border-radius: 8px;

  cursor: pointer;

  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
}

.article:hover {
  background-color: #201d2b;
}

.article .apresentation {
  width: 100%;
  max-width: 26rem;
}

.article .apresentation img {
  width: 100%;

  border-radius: 8px;
}

.article .details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  row-gap: 1rem;

  max-width: 20rem;
  
  padding: 1rem 0 1rem 2rem;

  color: #FFFFFF;
}

.article .details .title {
  font-family: 'metropolis-bold';
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
}

.article .details .subtitle {
  font-family: 'metropolis-regular';
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  color: var(--gray);
}

.article .details .date {
  font-family: 'metropolis-regular';
  font-size: 1rem;
  font-weight: 300;
  text-align: left;
}

@media (max-width: 790px) {
  .article {
    justify-content: unset;
  }

  .article .details {
    max-width: unset;

    padding: 1.5rem 0 0 0;
  }

  .article .apresentation {
    max-width: unset;
  }
}